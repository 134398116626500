import { Component, Vue, Ref } from "vue-property-decorator";
import axios, { CancelToken, CancelTokenSource } from "axios";
import {
  getSymbolCurrency,
  formatMoney,
  formatDate,
  htmlToText,
  nestedFreeze,
} from "@helpers";
import {
  formatDatePickerVal,
  parseDatePickerVal,
  periodRangeEndTimestamp,
  periodRangeStartTimestamp,
  getTimezoneHoursInSeconds,
  getPeriodDate,
} from "@/lib/date";
import {
  cashflowModule,
  profileModule,
  userModule,
  teamModule,
  walletModule,
} from "@store/namespaces";
import { CashflowActions } from "@store/modules/cashflow/types";
import { CashFlow, CashFlowType } from "@/types/cashFlow";
import { Currency } from "@/types/currency";
import { ProfileGetters } from "@store/modules/profile/types";
import { Role } from "@/types/role";
import { UserActions, UserGetters } from "@store/modules/user/types";
import { UserWebPreferencesKey } from "@/types/user";
import debounce from "lodash.debounce";
import {
  TableHeaderItems,
  TableHeaderValue,
  TopBarFilter,
  TopBarFilterItems,
} from "@/types/components/filter-table-top-bar";
import { DataTableHeader } from "vuetify";
import { cashFlowTypes } from "@config/cashFlow";
import { Period } from "@/types/datepicker";
import { TeamGetters, TeamActions } from "@store/modules/team/types";
import { WalletGetters } from "@store/modules/wallet/types";
import { HtmlElementId } from "@/types/element";
import { StepAlias } from "@/types/productTour";
import mockCashFlow from "@/mockData/cashFlow";
import { MultiSelectInstance } from "@/types/components/multiselect";

type TopBarTableFilter = TopBarFilter<"currency", Currency> &
  TopBarFilter<"type", CashFlowType>;

interface Filter {
  periodStart: number;
  periodEnd: number;
  forceUpdateKey: number;
  teamMembers: {
    teamleadEmails: string[];
    mediabuyerEmails: string[];
    otherEmails: string[];
  };
}

@Component
export default class CashFlowMixin extends Vue {
  @Ref("mediabuyers") private readonly mediabuyersRef?: MultiSelectInstance;
  @Ref("teamleads") private readonly teamleadsRef?: MultiSelectInstance;
  @Ref("teamMembers") private readonly teamMembersRef?: MultiSelectInstance;

  @cashflowModule.Action("filterCashflow")
  private readonly filterCashflowAction!: CashflowActions["filterCashflow"];
  @teamModule.Action("fetchTeamMembers")
  private readonly fetchTeamMembersAction!: TeamActions["fetchTeamMembers"];
  @profileModule.Getter("userHasRole")
  private readonly userHasRoleGetter!: ProfileGetters["userHasRole"];
  @profileModule.Getter("profileEmail")
  private readonly profileEmailGetter!: ProfileGetters["profileEmail"];
  @userModule.Action("fetchUserWebPreferences")
  private readonly fetchUserWebPreferencesAction!: UserActions["fetchUserWebPreferences"];
  @userModule.Action("updateUserWebPreferences")
  private readonly updateUserWebPreferencesAction!: UserActions["updateUserWebPreferences"];
  @userModule.Getter("userWebPreferences")
  private readonly userWebPreferencesGetter!: UserGetters["userWebPreferences"];
  @teamModule.Getter("teamleadMembers")
  private readonly teamleadMembersGetter!: TeamGetters["teamleadMembers"];
  @teamModule.Getter("mediabuyerMembers")
  private readonly mediabuyerMembersGetter!: TeamGetters["mediabuyerMembers"];
  @teamModule.Getter("members")
  private readonly membersGetter!: TeamGetters["members"];
  @teamModule.Getter("membersLoading")
  private readonly membersLoadingGetter!: TeamGetters["membersLoading"];
  @walletModule.Getter("walletsCurrencies")
  private readonly walletsCurrenciesGetter!: WalletGetters["walletsCurrencies"];

  private cashFlow: Readonly<CashFlow[]> = [];
  private searchStr = "";
  private loadingCashFlow = true;
  private forceUpdateUserWebPreferencesKey = Date.now();
  private itemsPerPage = 15;
  private topBarTableHeaderValue: Readonly<TableHeaderValue> = [];

  private topBarTableFilter: TopBarTableFilter = {
    currency: [],
    type: cashFlowTypes.map((value) =>
      Object.freeze({
        selected: true,
        value,
      })
    ),
  };

  private filter: Filter = {
    periodStart: 0,
    periodEnd: 0,
    forceUpdateKey: Date.now(),
    teamMembers: {
      teamleadEmails: [],
      mediabuyerEmails: [],
      otherEmails: [],
    },
  };

  private get isShowedMainIntro() {
    return this.$productTour.activeIntro.type === "product-tour";
  }

  protected get loadingCashFlowList(): boolean {
    return this.loadingCashFlow || this.membersLoadingGetter;
  }

  private get htmlElementId() {
    return {
      cashFlowFiltersContainerId: HtmlElementId.cashFlowFiltersContainerId,
      cashFlowFiltersButtonId: HtmlElementId.cashFlowFiltersButtonId,
      cashFlowDownloadFilesContainerId:
        HtmlElementId.cashFlowDownloadFilesContainerId,
      cashFlowTable: HtmlElementId.cashFlowTable,
    };
  }

  private get isShowTeamMemberSelect() {
    return (
      this.userHasRoleGetter(Role.ROLE_TEAMLEAD) &&
      !this.isShowTeamleadSelect &&
      !this.isShowMediabuyerSelect
    );
  }

  private get isShowTeamleadSelect() {
    return this.userHasRoleGetter([Role.ROLE_OWNER, Role.ROLE_ACCOUNTANT]);
  }

  private get isShowMediabuyerSelect() {
    return this.userHasRoleGetter([Role.ROLE_OWNER, Role.ROLE_ACCOUNTANT]);
  }

  private get userEmails() {
    if (this.isShowTeamMemberSelect) {
      return this.filter.teamMembers.otherEmails;
    }

    return [
      ...this.filter.teamMembers.teamleadEmails,
      ...this.filter.teamMembers.mediabuyerEmails,
    ];
  }

  protected get disabledSearch(): boolean {
    const rangeDay = Math.ceil(
      (this.filter.periodEnd - this.filter.periodStart) / (60 * 60 * 24)
    );

    return !rangeDay || rangeDay < 0 || rangeDay > 7 * 30;
  }

  private getDownloadedData() {
    return {
      items: this.formatedCashFlow.map((item) => ({
        ...item,
        date: this.formatDate(item.date),
        amount: this.formatMoney(item.amount),
        description: htmlToText(item.description),
      })),
      headers: this.headers
        .filter((header) => !!header.value)
        .reduce<Record<string, string>>((acc, { value, text }) => {
          acc[value] = text;

          return acc;
        }, {}),
    };
  }

  protected get rangeDate(): string[] {
    const range: string[] = [];

    if (this.filter.periodStart) {
      range.push(formatDatePickerVal(this.filter.periodStart * 1e3));
    }

    if (this.filter.periodEnd) {
      range.push(formatDatePickerVal(this.filter.periodEnd * 1e3));
    }

    return range;
  }

  protected set rangeDate(val: string[]) {
    const dateStart = val[0] ? parseDatePickerVal(val[0]) : null;
    const dateEnd = val[1] ? parseDatePickerVal(val[1]) : null;

    if (dateStart) {
      this.filter.periodStart = periodRangeStartTimestamp(dateStart);
    } else {
      this.filter.periodStart = 0;
    }

    if (dateEnd) {
      this.filter.periodEnd = periodRangeEndTimestamp(dateEnd);
    } else {
      this.filter.periodEnd = 0;
    }
  }

  private get headers() {
    const headers: (DataTableHeader & { enabled?: boolean })[] = [
      {
        value: "date",
        text: this.$vuetify.lang.t("$vuetify.dashboard.table.header.date"),
        align: "start",
      },
      {
        value: "type",
        text: this.$vuetify.lang.t("$vuetify.dashboard.table.header.type"),
        align: "start",
        sortable: false,
      },
      {
        text: this.$vuetify.lang.t("$vuetify.dashboard.table.header.account"),
        value: "wallet",
        align: "center",
        enabled: this.userHasRoleGetter([
          Role.ROLE_TEAMLEAD,
          Role.ROLE_ACCOUNTANT,
          Role.ROLE_OWNER,
        ]),
      },
      {
        value: "amount",
        text: this.$vuetify.lang.t("$vuetify.dashboard.table.header.amount"),
        align: "center",
      },
      {
        value: "currencySymbol",
        text: this.$vuetify.lang.t("$vuetify.dashboard.table.header.currency"),
        align: "center",
        sortable: false,
      },
      {
        value: "description",
        text: this.$vuetify.lang.t(
          "$vuetify.dashboard.table.header.description"
        ),
        align: "start",
        sortable: false,
      },
    ];

    return headers.filter(({ enabled = true }) => enabled);
  }

  private get excludeTopBarHeaders(): string[] {
    return ["date"];
  }

  private get filterFields() {
    return [
      {
        title: this.$vuetify.lang.t("$vuetify.dashboard.table.header.currency"),
        type: "checkbox",
        name: "currency",
        items: this.walletsCurrenciesGetter.map((value) => ({
          text: getSymbolCurrency(value),
          value,
        })),
      },
      {
        title: this.$vuetify.lang.t("$vuetify.dashboard.table.header.type"),
        type: "checkbox",
        name: "type",
        items: cashFlowTypes.map((value) => ({
          text: this.getCashFlowType(value),
          value,
        })),
      },
    ];
  }

  private get topBarTableHeaderItems() {
    const items: TableHeaderItems = this.headers.filter(
      ({ value }) => !this.excludeTopBarHeaders.includes(value)
    );

    return items;
  }

  private get tableHeaders() {
    const topBarTableHeaderValue = this.topBarTableHeaderValue;

    return this.headers.filter(
      ({ value }) =>
        this.excludeTopBarHeaders.includes(value) ||
        topBarTableHeaderValue.some(
          ({ columnName, selected }) => columnName === value && selected
        )
    );
  }

  private get filteredCashFlow() {
    if (
      this.isShowedMainIntro &&
      this.$productTour.activeIntro.step.alias === StepAlias.MOCK_CASH_FLOW_LIST
    ) {
      return mockCashFlow;
    }

    const { currency: filterCurrency, type: filterType } =
      this.topBarTableFilter;

    return this.cashFlow.filter((item) => {
      return (
        filterCurrency.some(
          ({ value, selected }) => value === item.currency && selected
        ) &&
        filterType.some(
          ({ value, selected }) => value === item.type && selected
        )
      );
    });
  }

  private get formatedCashFlow() {
    return this.filteredCashFlow.map((item) => {
      return {
        ...item,
        typeCode: item.type,
        date: new Date(item.date).getTime(),
        type: this.getCashFlowType(item.type),
        currencySymbol: getSymbolCurrency(item.currency),
        description: this.formatDescription(item),
      };
    });
  }

  private get items() {
    const searchStr = this.searchStr.trim().toLowerCase();

    return nestedFreeze(
      this.formatedCashFlow.filter((item) => {
        return (
          !searchStr ||
          this.formatMoney(item.amount).includes(searchStr) ||
          this.formatDate(item.date).includes(searchStr) ||
          item.type.toLowerCase().includes(searchStr) ||
          item.description.toLowerCase().includes(searchStr)
        );
      })
    );
  }

  private onChangeTopBarTableFilter(val: TopBarTableFilter) {
    this.topBarTableFilter = val;
  }

  private onChangeTableHeaderValue(value: TableHeaderValue) {
    this.topBarTableHeaderValue = value;
  }

  protected async fetchAllCashFlow() {
    this.teamMembersRef?.selectAll();
    this.mediabuyersRef?.selectAll();
    this.teamleadsRef?.selectAll();

    await this.$nextTick();

    this.refreshCashFlow();
  }

  protected refreshCashFlow(): void {
    this.filter.forceUpdateKey++;

    if (!this.filter.teamMembers.otherEmails.length) {
      this.filter.teamMembers.otherEmails = [this.profileEmailGetter];
    }

    if (!this.filter.teamMembers.teamleadEmails.length) {
      this.filter.teamMembers.teamleadEmails = this.teamleadMembersGetter
        .map(({ email }) => email)
        .concat([this.profileEmailGetter]);
    }

    if (!this.filter.teamMembers.mediabuyerEmails.length) {
      this.filter.teamMembers.mediabuyerEmails =
        this.mediabuyerMembersGetter.map(({ email }) => email);
    }
  }

  private getCashFlowTypeInfo(type: CashFlowType) {
    switch (type) {
      case CashFlowType.TRANSACTION_FEE:
        return {
          icon: "$transactionFee",
          color: "warning",
          textColor: "white",
        };

      case CashFlowType.INT_TRANSACTION_FEE:
        return {
          icon: "$intTransactionFee",
          color: "warning",
          textColor: "white",
        };

      case CashFlowType.CARD_ISSUE:
        return {
          icon: "$cardIssueFee",
          color: "warning",
          textColor: "white",
        };

      case CashFlowType.DECLINE_FEE:
        return {
          icon: "$declineFee",
          color: "warning",
          textColor: "white",
        };

      case CashFlowType.INCOMING_FUNDS_TRANSFER:
        return {
          icon: "$incomingFundsTransfer",
          color: "success",
          textColor: "white",
        };

      case CashFlowType.FUNDS_DEPOSIT:
        return {
          icon: "$fundsDeposit",
          color: "success",
          textColor: "white",
        };

      case CashFlowType.CARD_DEPOSIT:
        return {
          icon: "$cardDeposit",
          color: "success",
          textColor: "white",
        };

      case CashFlowType.CARD_WITHDRAW:
        return {
          icon: "$cardWithdraw",
          color: "purple lighten-3",
          textColor: "white",
        };

      case CashFlowType.CARD_CLOSE:
        return {
          icon: "$cardCloseRefund",
          color: "primary",
          textColor: "white",
        };

      case CashFlowType.FUNDS_TRANSFER:
        return {
          icon: "$fundsTransfer",
          color: "grey",
          textColor: "white",
        };

      case CashFlowType.FUNDS_EXCHANGE:
        return {
          icon: "$fundsExchange",
          color: "grey",
          textColor: "white",
        };

      case CashFlowType.TRANSACTION_REFUND:
        return {
          icon: "$transactionRefund",
          color: "primary",
          textColor: "white",
        };

      case CashFlowType.TRANSACTION_REFUND_TO_BALANCE:
        return {
          icon: "$transactionRefundToBalance",
          color: "primary",
          textColor: "white",
        };

      default:
        return null;
    }
  }

  private formatDate(
    val: string | number,
    options: {
      showDate?: boolean;
      showTime?: boolean;
    } = {}
  ) {
    const { showDate = true, showTime = true } = options;

    return formatDate(val, { showTime, showDate });
  }

  private formatMoney(value: number, currency?: Currency) {
    return formatMoney({ value, currency, showSymbol: !!currency });
  }

  private getCashFlowType(type: CashFlowType) {
    return this.$vuetify.lang.t(
      `$vuetify.dashboard.cash_flow.type.${type.toLowerCase()}`
    );
  }

  private async filterCashflow(params: { cancelToken?: CancelToken } = {}) {
    const { cancelToken } = params;
    this.loadingCashFlow = true;

    try {
      const cashFlow = await this.filterCashflowAction({
        cancelToken,
        periodStart: this.filter.periodStart + getTimezoneHoursInSeconds(),
        periodEnd: this.filter.periodEnd + getTimezoneHoursInSeconds(),
        userEmails: this.userEmails,
      });

      this.cashFlow = Object.freeze(cashFlow);
      this.loadingCashFlow = false;
    } catch (error) {
      if (!axios.isCancel(error)) {
        this.cashFlow = [];
        this.loadingCashFlow = false;
      }
    }
  }

  private initWatcherFilterCashflow() {
    let cancelTokenSource: CancelTokenSource | null = null;

    this.$watch(
      () => {
        return [
          // this.filter.periodStart,
          // this.filter.periodEnd,

          this.filter.forceUpdateKey,
        ].join("-");
      },
      () => {
        if (!this.filter.periodStart || !this.filter.periodEnd) return;

        cancelTokenSource?.cancel();
        cancelTokenSource = axios.CancelToken.source();
        cancelTokenSource.token;

        this.filterCashflow({
          cancelToken: cancelTokenSource.token,
        });
      },
      {
        immediate: true,
      }
    );

    this.$once("hook:beforeDestroy", () => {
      cancelTokenSource?.cancel();
    });
  }

  private formatDescription(item: CashFlow) {
    if (!item.jsonDesc) return item.description;

    let descParts = [item.description];

    switch (item.jsonDesc.type) {
      case CashFlowType.FUNDS_EXCHANGE:
        {
          const { amount, currency, fromAmount, fromCurrency, user } =
            item.jsonDesc;

          descParts = [
            `${this.formatMoney(fromAmount, fromCurrency)} ⭢ ${this.formatMoney(
              amount,
              currency
            )}`,
            `Wallet: ${user}`,
          ];
        }

        break;

      case CashFlowType.CARD_ISSUE:
        {
          const { maskedPan, user } = item.jsonDesc;

          descParts = [`Card number: ${maskedPan}`, `Owner: ${user}`];
        }

        break;

      case CashFlowType.TRANSACTION_REFUND:
        {
          const { maskedPan, user, desc } = item.jsonDesc;

          descParts = [
            `Card number: ${maskedPan}`,
            `Description: ${desc}`,
            `Owner: ${user}`,
          ];
        }

        break;

      case CashFlowType.TRANSACTION_FEE:
      case CashFlowType.INT_TRANSACTION_FEE:
      case CashFlowType.DECLINE_FEE:
        {
          const { maskedPan, user, txnId } = item.jsonDesc;

          descParts = [
            `Card number: ${maskedPan}`,
            `Transaction ID: ${txnId}`,
            `Wallet: ${user}`,
          ];
        }

        break;

      case CashFlowType.TRANSACTION_REFUND_TO_BALANCE:
        {
          const { maskedPan, user, desc } = item.jsonDesc;

          descParts = [
            `Card number: ${maskedPan}`,
            `Description: ${desc}`,
            `From: ${maskedPan}`,
            `Owner: ${user}`,
          ];
        }

        break;

      case CashFlowType.FUNDS_DEPOSIT:
        {
          const { paymentSystem, participant, user } = item.jsonDesc;

          descParts = [
            `To: ${user}`,
            `Payment system: ${paymentSystem ?? participant}`,
          ];
        }

        break;

      case CashFlowType.CARD_DEPOSIT:
        {
          const { maskedPan, user } = item.jsonDesc;

          descParts = [`From: ${user}`, `To: ${maskedPan}`];
        }

        break;

      case CashFlowType.CARD_WITHDRAW:
        {
          const { maskedPan, user } = item.jsonDesc;

          descParts = [`From: ${maskedPan}`, `To: ${user}`];
        }

        break;

      case CashFlowType.CARD_CLOSE:
        {
          const { maskedPan, user, participant } = item.jsonDesc;

          descParts = [
            `Card number: ${maskedPan}`,
            `Owner: ${user}`,
            `Closed by: ${participant}`,
          ];
        }

        break;

      case CashFlowType.FUNDS_TRANSFER:
        {
          const { participant, user } = item.jsonDesc;

          descParts = [`From: ${user}`, `To: ${participant}`];
        }

        break;

      case CashFlowType.INCOMING_FUNDS_TRANSFER:
        {
          const { participant, user } = item.jsonDesc;

          descParts = [`From: ${participant}`, `To: ${user}`];
        }

        break;

      default:
        break;
    }

    return descParts.filter(Boolean).join(`<br />`) || item.description;
  }

  private async initWebPreferences() {
    await this.fetchUserWebPreferencesAction({
      key: UserWebPreferencesKey.CASH_FLOW,
    });

    const { value } = this.userWebPreferencesGetter({
      key: UserWebPreferencesKey.CASH_FLOW,
    });

    const userWebPreferences = value as Partial<{
      topBarTableHeaderValue: TableHeaderValue;
      topBarTableFilter: Partial<TopBarTableFilter>;
      filter: Partial<Filter>;
      itemsPerPage: number;
    }> | null;

    this.itemsPerPage = userWebPreferences?.itemsPerPage ?? this.itemsPerPage;
    this.topBarTableHeaderValue = this.topBarTableHeaderValue.map(
      ({ columnName, text, selected }) =>
        Object.freeze({
          columnName,
          text,
          selected:
            userWebPreferences?.topBarTableHeaderValue?.find(
              (item) => item.columnName === columnName
            )?.selected ?? selected,
        })
    );

    this.topBarTableFilter = Object.entries(
      this.topBarTableFilter
    ).reduce<TopBarTableFilter>((acc, [fieldName_, fieldValue_]) => {
      const fieldName = fieldName_ as keyof TopBarTableFilter;
      const fieldValue = fieldValue_ as TopBarFilterItems<string>;

      const fieldValueFromStorage = (
        userWebPreferences?.topBarTableFilter
          ? userWebPreferences.topBarTableFilter[fieldName]
          : undefined
      ) as TopBarFilterItems<string> | undefined;

      return {
        ...acc,
        [fieldName]: fieldValue.map(({ value, selected }) =>
          Object.freeze({
            value,
            selected:
              fieldValueFromStorage?.find((item) => item?.value === value)
                ?.selected ?? selected,
          })
        ),
      };
    }, this.topBarTableFilter);

    const teamleadOwnersFromStorage =
      userWebPreferences?.filter?.teamMembers?.teamleadEmails?.filter(
        (teamleadEmail) =>
          this.teamleadMembersGetter.some(
            ({ email }) => email === teamleadEmail
          )
      );

    this.filter.teamMembers.teamleadEmails = teamleadOwnersFromStorage?.length
      ? teamleadOwnersFromStorage
      : [];

    const mediabuyerOwnersFromStorage =
      userWebPreferences?.filter?.teamMembers?.mediabuyerEmails?.filter(
        (mediabuyerEmail) =>
          this.mediabuyerMembersGetter.some(
            ({ email }) => email === mediabuyerEmail
          )
      );

    this.filter.teamMembers.mediabuyerEmails =
      mediabuyerOwnersFromStorage?.length ? mediabuyerOwnersFromStorage : [];

    const otherOwnersFromStorage =
      userWebPreferences?.filter?.teamMembers?.otherEmails?.filter(
        (otherEmail) =>
          otherEmail === this.profileEmailGetter ||
          this.membersGetter.some(({ email }) => email === otherEmail)
      );

    this.filter.teamMembers.otherEmails = otherOwnersFromStorage?.length
      ? otherOwnersFromStorage
      : [];

    this.refreshCashFlow();

    this.$watch(
      () => {
        return this.topBarTableFilter;
      },
      () => {
        this.forceUpdateUserWebPreferencesKey++;
      }
    );

    this.$watch(
      () => {
        return this.topBarTableHeaderValue;
      },
      () => {
        this.forceUpdateUserWebPreferencesKey++;
      }
    );

    return this.$watch(
      () => {
        return [
          this.itemsPerPage,
          this.filter.teamMembers.teamleadEmails.length,
          this.filter.teamMembers.mediabuyerEmails.length,
          this.filter.teamMembers.otherEmails.length,
          this.forceUpdateUserWebPreferencesKey,
        ].join("-");
      },
      debounce(() => {
        const { teamMembers } = this.filter;

        this.updateUserWebPreferencesAction({
          key: UserWebPreferencesKey.CASH_FLOW,
          value: {
            filter: { teamMembers },
            topBarTableFilter: this.topBarTableFilter,
            topBarTableHeaderValue: this.topBarTableHeaderValue,
            itemsPerPage: this.itemsPerPage,
          },
        });
      }, 500)
    );
  }

  protected createdHook(): void {
    this.topBarTableHeaderValue = this.topBarTableHeaderItems.map(
      ({ value, text }) =>
        Object.freeze({
          columnName: value,
          text,
          selected: true,
        })
    );
  }

  protected async mountedHook(): Promise<void> {
    const { start: periodStart, end: periodEnd } = getPeriodDate(
      Period.LAST_MONTH
    );

    this.filter.periodStart = periodRangeStartTimestamp(periodStart);
    this.filter.periodEnd = periodRangeEndTimestamp(periodEnd);

    await this.fetchTeamMembersAction();

    this.$watch(
      () => {
        return this.walletsCurrenciesGetter.length > 0;
      },
      async (isReadyWalletsCurrencies) => {
        if (!isReadyWalletsCurrencies) return;

        this.topBarTableFilter.currency = this.walletsCurrenciesGetter.map(
          (value) =>
            Object.freeze({
              selected: true,
              value,
            })
        );

        await this.initWebPreferences();
        this.initWatcherFilterCashflow();
      },
      {
        immediate: true,
      }
    );
  }
}
