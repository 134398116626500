import { CashFlow, CashFlowType } from "@/types/cashFlow";
import { Currency } from "@/types/currency";

const mockCashFlow: CashFlow[] = [
  {
    type: CashFlowType.CARD_DEPOSIT,
    date: "2024-01-12T16:20:27.037115+03:00",
    amount: 10,
    currency: Currency.USD,
    description: "From: test@test.ru\nTo: 531993********52",
    jsonDesc: {
      type: CashFlowType.CARD_DEPOSIT,
      user: "test@test.ru",
      amount: 10,
      currency: Currency.USD,
      maskedPan: "531993********52",
    },
    wallet: "test@test.ru",
  },
];

export default mockCashFlow;
